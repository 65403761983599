<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ t("Cài đặt") }}</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-tabs>
        <b-tab>
          <template #title>
            <feather-icon icon="BellIcon" />
            <span>{{ t("Thông báo") }}</span>
          </template>
          <setting-tab-notification />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="CreditCardIcon" />
            <span>{{ t("Hóa đơn") }}</span>
          </template>
          <setting-tab-invoice />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="ColumnsIcon" />
            <span>{{ t("Đợt thanh toán") }}</span>
          </template>
          <setting-tab-general-data />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="MailIcon" />
            <span>{{ t("Cấu hình email") }}</span>
          </template>
          <setting-tab-email />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="FileIcon" />
            <span>{{ t("Cấu hình hóa đơn đầu vào") }}</span>
          </template>
          <setting-tab-tax />
        </b-tab>
      </b-tabs>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BTab,
  BTabs,
  BCardTitle,
  BCardHeader,
} from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import SettingTabNotification from "./tabs/SettingTabNotification.vue";
import SettingTabInvoice from "./tabs/SettingTabInvoice.vue";
import SettingTabGeneralData from "./tabs/SettingTabGeneralData.vue";
import SettingTabEmail from "./tabs/SettingTabEmail.vue";
import SettingTabTax from "./tabs/SettingTabTax.vue";

export default {
  components: {
    BCard,
    BCardBody,
    BTab,
    BTabs,
    BCardTitle,
    BCardHeader,
    SettingTabNotification,
    SettingTabInvoice,
    SettingTabGeneralData,
    SettingTabEmail,
    SettingTabTax
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
};
</script>
