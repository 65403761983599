<template>
  <validation-observer #default="{ handleSubmit, invalid }" ref="refFormObserver">
    <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
      <b-row class="mt-1">
        <b-col>
          <validation-provider #default="validationContext" name="autoNotificationNextBillingDateAt" rules="required">
            <b-form-group>
              <template v-slot:label>
                {{ t("Tài khoản đăng nhập TCT") }}<span class="text-danger"> (*) </span>
              </template>
              <b-form-input id="ibaseTctUsername" v-model="data.ibaseTctUsername" placeholder="" trim
                :state="getValidationState(validationContext)" />
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider #default="validationContext" name="autoNotificationNextBillingDateAt" rules="required">
            <b-form-group>
              <template v-slot:label>
                {{ t("Mật khẩu đăng nhập TCT") }}<span class="text-danger"> (*) </span>
              </template>
              <b-form-input id="ibaseTctPassword" v-model="data.ibaseTctPassword" placeholder="" trim
                :state="getValidationState(validationContext)" />
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-button class="mt-2" variant="primary" block type="submit" :disabled="invalid">
        {{ t("Lưu") }}
      </b-button>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BButton,
  BFormRadio,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import { required, between } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import useJwt from "@/auth/jwt/useJwt";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { parseDateToString, updateUserConfiguration } from "@/auth/utils";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import SelectDateTime from "@/views/components/SelectDateTime.vue";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormRadio,
    BFormGroup,
    BFormInput,
    SelectDateTime,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      data: {
        ibaseTctUsername: "",
        ibaseTctPassword: "",
      },

      // validation
      required,
      between,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation();

    const { t } = useI18nUtils();
    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      t,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      useJwt
        .getUserConfiguration()
        .then((response) => {
          updateUserConfiguration(response.data);
          this.data = response.data;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Thông báo`,
              icon: "UserIcon",
              variant: "danger",
              text: `Có lỗi xảy ra khi lấy thông tin cài đặt. Vui lòng thử lại sau`,
            },
          });
        });
    },

    onSubmit() {
      const data = new FormData();
      data.append(
        "ibaseTctUsername",
        this.data.ibaseTctUsername ?? ""
      );
      data.append(
        "ibaseTctPassword",
        this.data.ibaseTctPassword ?? ""
      );
      useJwt
        .updateUserConfiguration(data)
        .then((response) => {
          updateUserConfiguration(response.data);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Thông báo`,
              icon: "UserIcon",
              variant: "success",
              text: `Thông tin đã được cập nhật thành công`,
            },
          });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status === 422 &&
            error.response.data &&
            error.response.data.errors
          ) {
            this.$refs.refFormObserver.setErrors(error.response.data.errors);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Thông báo",
                text: error.response.data.message
                  ? error.response.data.message
                  : "Có lỗi xảy ra, vui lòng thử lại sau",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
  },
};
</script>
