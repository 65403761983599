<template>
  <validation-observer
    #default="{ handleSubmit, invalid }"
    ref="refFormObserver"
  >
    <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
      <b-row class="mt-2">
        <b-col cols="12">
          <span>{{ t("Tách hóa đơn theo khoản thu và tài khoản thanh toán") }}</span>
          <small class="text-danger"><br>LƯU Ý: Chỉ áp dụng trong trường hợp import hóa đơn, không áp dụng trong trường hợp lập hóa đơn riêng lẻ mà có từ 2 tài khoản thu khác nhau trong danh sách dịch vụ</small>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.groupInvoiceByFeeAndTingee"
              name="groupInvoiceByFeeAndTingee"
              :value="true"
              class="custom-control-primary"
            >
              {{ t("Bật") }}
            </b-form-radio>

            <b-form-radio
              v-model="data.groupInvoiceByFeeAndTingee"
              name="groupInvoiceByFeeAndTingee"
              :value="false"
              class="custom-control-primary"
            >
              {{ t("Tắt") }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <span>{{ t("Tự động sử dụng tiền thừa vào hóa đơn kỳ sau") }}</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.autoUsePrepaid"
              name="autoUsePrepaid"
              :value="true"
              class="custom-control-primary"
            >
              {{ t("Bật") }}
            </b-form-radio>

            <b-form-radio
              v-model="data.autoUsePrepaid"
              name="autoUsePrepaid"
              :value="false"
              class="custom-control-primary"
            >
              {{ t("Tắt") }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>

      <b-button
        class="mt-2"
        variant="primary"
        block
        type="submit"
        :disabled="invalid"
      >
        {{ t("Lưu") }}
      </b-button>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BButton,
  BFormRadio,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import { required, between } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import useJwt from "@/auth/jwt/useJwt";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { parseDateToString, updateUserConfiguration } from "@/auth/utils";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import SelectDateTime from "@/views/components/SelectDateTime.vue";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormRadio,
    BFormGroup,
    BFormInput,
    SelectDateTime,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      data: {
        autoUsePrepaid: false,
        groupInvoiceByFeeAndTingee: false
      },

      // validation
      required,
      between,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation();

    const { t } = useI18nUtils();
    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      t,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      useJwt
        .getUserConfiguration()
        .then((response) => {
          updateUserConfiguration(response.data);
          this.data = response.data;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Thông báo`,
              icon: "UserIcon",
              variant: "danger",
              text: `Có lỗi xảy ra khi lấy thông tin cài đặt. Vui lòng thử lại sau`,
            },
          });
        });
    },

    onSubmit() {
      const data = new FormData();
      data.append("autoUsePrepaid", this.data.autoUsePrepaid ?? false);
      data.append("groupInvoiceByFeeAndTingee", this.data.groupInvoiceByFeeAndTingee ?? false);

      useJwt
        .updateUserConfiguration(data)
        .then((response) => {
          updateUserConfiguration(response.data);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Thông báo`,
              icon: "UserIcon",
              variant: "success",
              text: `Thông tin đã được cập nhật thành công`,
            },
          });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status === 422 &&
            error.response.data &&
            error.response.data.errors
          ) {
            this.$refs.refFormObserver.setErrors(error.response.data.errors);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Thông báo",
                text: error.response.data.message
                  ? error.response.data.message
                  : "Có lỗi xảy ra, vui lòng thử lại sau",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
  },
};
</script>
